.about1-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00B1AD;
    font-family: var(--font2);
    color: var(--color4);
    text-align: center;
    padding: 50px 0;
    margin-bottom: -200px;

}
.about1-container img{
    z-index: 2;
    margin-bottom: -50px;
    align-self: flex-end;
}
.about1-texts{
    width: 70%;
    background-color: var(--color4);
    border-radius: 71px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 50px 100px 20px;
    z-index: 1;
    position: relative;
}
.about1-texts p{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--color3);
    text-align: start;

}

@media (max-width: 650px) {
    .about1-container{
        margin-bottom: -50px;
    }
    .about1-container img{
        width: 100%;
    }
    .about1-texts{
        border-radius: 0;
    }
    
}
