.carbon5-container{
    font-family: var(--font2);
    position: relative;
    color: var(--color4);
    background-color: var(--color1);
    width: 90%;
    margin: 200px auto 0;
    padding: 30px 70px;
    border-radius: 125px ;
    z-index: 2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);    
}
.carbon5-container h2{
    font-size: 36px;
    z-index: 2;
}

.carbon5-title{
    background-color: var(--color2);
    text-align: center;
    width: 80%;
    margin: -100px auto 0;
    padding: 10px 0;
    border-radius: 27px;
}
.carbon5-title h3{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
    text-align: center;
    color: var(--color4);
}

.carbon5-texts{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin: 50px 0;
}

.carbon5-texts p{
    font-weight:400;
    font-size: 20px;
}
.carbon5-texts1 span{
    font-size: 21px;
    font-weight:bold;
}
.carbon5-texts2 h3{
    font-size: 40px;
    font-weight:bold;
    margin: 20px 0 10px;
}
.carbon5-texts3 span{
    font-size: 23px;
    font-weight:bold;
}
@media (max-width: 800px){
    .carbon5-container{
        width: 100%;
        margin: 100px 0 ;
        padding: 50px 0;
        border-radius: 0;
    }
    .carbon5-title{
        width: 100%;
    }
    .carbon5-container h2{
        margin-top: 70px;
        text-align: center;
    }
    .carbon5-texts1{
        grid-template-columns: repeat(2,1fr);
        text-align: center;
        margin: 0;
        margin-left: 10px;
        

    }
    .carbon5-texts2{
        grid-template-columns: repeat(1,1fr);
        text-align: center;
        margin: 0;
        margin-left: 10px;


    }
    .carbon5-texts3{
        grid-template-columns: repeat(1,1fr);
        text-align: center;
        margin: 0;
        margin-left: 10px;



    }
    .carbon5-texts {
        width: 90%;
        margin-left: 10px;

    }
}
@media (max-width: 500px){
    .carbon5-title h3{
        font-size: 28px;
    }
}
@media (max-width: 400px){
    .carbon5-texts1{
        grid-template-columns: repeat(1,1fr);

    }
}
