body {
    margin: 0;
    font: normal 75% Arial, Helvetica, sans-serif;
  }
  canvas {
    display: block;
    vertical-align: bottom;
  }
  /* ---- tsparticles container ---- */
  .github {
    bottom: 10px;
    right: 10px;
    position: fixed;
    border-radius: 10px;
    background: #fff;
    padding: 0 12px 6px 12px;
    border: 1px solid #000;
  }
  
  .github a:hover,
  .github a:link,
  .github a:visited,
  .github a:active {
    color: #000;
    text-decoration: none;
  }
  
  .github img {
    height: 30px;
  }
  
  .github #gh-project {
    font-size: 20px;
    padding-left: 5px;
    font-weight: bold;
    vertical-align: bottom;
  }