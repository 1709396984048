.solutions2-container{
    width: 90%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.solutions2-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}
.solutions2-title img{
   width: 70%;  
}
.solutions2-title p{
    color: var(--color3);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.solutions2-title h2{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: var(--color4);
}

.solutions2-contents div{
    display: flex;
    margin-bottom: 50px ;
}
.solutions2-contents img{
    width: 50%;
    height: auto;
    border-radius: 20px;
    max-height: 355px;
}
.solutions2-texts{
    display: block;
    flex-direction: column;
    width: 65%;
    margin-left: 20px;
    justify-content: center;
    
}
.solutions2-texts span{
color: var(--color2);
}
.solutions2-texts h2{
    background: var(--color2);
    font-family: var(--font2);
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    padding: 20px 30px;
    width:max-content;
    text-align: center;
    color: var(--color4);
    border-radius: 27px;
}
.solutions2-texts h3{
    color: var(--color2);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.solutions2-texts p{
    font-family: var(--font2);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    color: var(--color3);
}

@media (max-width: 800px) {
    .solutions2-title{
        width: 95%;
    }
    .solutions2-title img{
        width: 90%;
    }   
    .solutions2-title h2{
        font-size: 20px;
        padding: 10px;

    }
    .solutions2-contents div{
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }
    .solutions2-contents img{
        width: 100%;
    }
}
