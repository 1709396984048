.carbon4-container{
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.carbon4-texts{
    border-left: solid 10px var(--color1);
    padding: 10px 50px;
}
.carbon4-texts h2{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: var(--color2);
}

.carbon4-texts p{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--color3);
}
.carbon4-texts p:nth-child(3){
    color: var(--color1);
}
.carbon4-container img{
    border-radius: 27px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 1200px) {
    .carbon4-container{
        width: 95%;
    }
}
@media (max-width: 800px) {
    .carbon4-container{
        flex-direction: column;
    }
    .carbon4-container img{
        width: 105%;
        margin-top: 50px;
    }
}
