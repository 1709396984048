
@keyframes scroll {
    0% {
        transform: translateX(0);
   }
    100% {
        transform: translateX(calc(-1000px * 7));
   }
}
.slider {
    background: white;
    
    margin: auto;
    position: relative;
    width: 100%;
}
.slider::before, .slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 500px;
    position: absolute;
    width:100px;
    z-index: 2;
}
.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
.slider::before {
    left: 0;
    top: 0;
}
.slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(550px * 14);
}
.slider .slide {
    height: 100%;
    width: 100%;
    margin: 30px 50px;
}
.slide img{
    cursor: pointer;
    width: 480px !important;
}
.slide img:hover{
    transform: scale(1.1);
}

