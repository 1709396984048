.nav-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    position: -webkit-sticky; 
    position: sticky;
    width: 100%;
    max-width: 100%;
    top: 0px;
    background-color: var(--color4);
    box-shadow: -1px 5px 11px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px 5px 11px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 5px 11px -3px rgba(0,0,0,0.75);
    z-index: 5;
}

.nav-logo {
    margin-left: 25px;
}
.nav-router{
    display: flex;
    align-items: center;
    margin-right: 20px;

}
.nav-router a{
    text-decoration: none;
    color: var(--color3);
    font-family: var(--font1);
    font-size: 14px;
    margin: 0 10px;
    font-weight: 600;
    letter-spacing: 1px;
}
.link:hover{
    transform: scale(1.1);
    opacity: .7;
}
.nav-social a:hover{
    transform: scale(1.2);
    opacity: .7;
}
.nav-social a:last-child {
        margin-right: 25px;

}
@media (min-width: 1100px){
    .icon-burger{
        display: none;
    }
    .nav-burger{
        display: none;
    }
    
}
@media (max-width: 1100px) {
    .nav-router{
        display: none;
    }
    .icon-burger{
        display: flex;
        cursor: pointer;
        margin-right: 20px;
    }
    .nav-burger{
        display: none;

        flex-direction: column;
        position: absolute;
        right: 0;
        top: 0;
        background-color: var(--color1);
        height: 100vh;
        width: 60%;
        -webkit-box-shadow: -8px 0px 2px -4px rgba(0,0,0,0.51);
        -moz-box-shadow: -8px 0px 2px -4px rgba(0,0,0,0.51);
        box-shadow: -8px 0px 2px -4px rgba(0,0,0,0.51);
    }
    .link{
        margin-left: 30px ;
        padding: 30px 10px;
        display: flex;
        flex-direction: row;
        
    }
    .link a{
        width: 100%;
        text-decoration: none;
        font-size: 20px;
        margin-left: 5px;
        color: var(--color4);
        font-family: var(--font2);
        font-weight: 600;
    }
}
@keyframes openBurger {
    0%{
        transform: translateX(250px);
    }100%{
        transform: translateX(0px);

    }
}
@keyframes closedBurger {
    0%{
        transform: translateX(0px);
    }100%{
        transform: translateX(500px);

    }
}