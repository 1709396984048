.footer-container{
    display: flex;
    justify-content: space-between;
    padding: 20px 50px ;
    position: relative;
    background-color: var(--color2);
    margin-top: 50px;
}

.footer-container p{
    text-align: end;
    font-family: var(--font2);
    font-size: 15px;
    color: var(--color4);
}
.footer-container span{
    font-size: 24px;
    z-index: 3;
}
/* Media Query */
@media (max-width: 550px) {
   .footer-container{
        flex-direction: column;
   }

.footer-container p{
    text-align: center;
    font-size: 13px;
}
}
