.solutions3-container{
    width: 80%;
    margin: 0 auto;
}
.solutions3-title{
    background-color: var(--color2);
    border-radius: 27px;
    width: 90%;
    margin: 0 auto 50px;
    padding: 10px 30px;
}
.solutions3-title h2{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    color: var(--color4);
    margin: 0;
}
.solutions3-contents{
    display: flex;
    justify-content: space-around;
}
.solutions3-content{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 58px;
    padding: 0 20px;
    width: 30%;
}

.solutions3-content h3{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: var(--color1);
}
.solutions3-content div{
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

}
.solutions3-content p{
    width: 100%;
    font-family: var(--font2);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--color3);
    margin: 0;
}

@media (max-width: 800px) {
    .solutions3-title{
        width: 100%;
        padding: 10px;
    }
    .solutions3-title h2{
        font-size: 20px;
    }
    .solutions3-contents{
        flex-direction: column;
        align-items: center;
    }
    .solutions3-content{
        width: 80%;
        margin-bottom: 50px;
    }

}

@media (max-width: 400px) {
    .solutions3-content{
        width: 100%;
    }
}