.home4-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font2);
    padding: 100px 0;
}
.home4-divider{
    width: 80%;
    height: 2px;
    background: gray;
    margin-bottom: 50px;
}
.home4-container h2{
    z-index: 1;
    font-size: 36px;
    text-align: center;
    color: var(--color1);
}
.home4-logos{
    width: 100%;
    display: flex;
    justify-content: center ;
    align-items: center;
}
.home4-logos img{ 
    width: 18%;
    margin: 0 50px;
}
.home4-logos img:nth-child(2){ 
    width: 10%;
}


@media (max-width: 550px){
    .home4-container{
        padding: 30px 0;
    }
    .home4-container h2{
        width: 90%;
    }
    .home4-logos{
        flex-direction: column;
    }
    .home4-logos img{
        margin: 30px;
        width: 50%;
    }
    .home4-logos img:nth-child(2){
        width: 30%;
    }
   
}