:root{
    --color1:#CF2B74;
    --color2:#058D89;
    --color3:#3D3D3D;
    --color4:#EEECE4;
    --font1:'Montserrat', sans-serif;
    --font2:'Montserrat Alternates', sans-serif;
}

html,body{
    margin: 0;
    background-color: var(--color4);
    overflow-x:hidden;
}

