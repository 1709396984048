.home3-container{
    width: 80%;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home3-container h3{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    text-align: center; 
    color: var(--color3);
    width: 80%;
}

.home3-icons{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 100px 0;
}

.home3-icons div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 30%;
    min-height: 300px;
    margin: 0 50px;
    border-radius: 58px;
    padding: 30px 20px ;  
}
.home3-icons img{
    margin-top: -70px;
}
.home3-icons h4{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #CF2B74;
    margin: 0;

}
.home3-icons p{
    text-align: center;
    font-family: var(--font2);
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}



@media (max-width: 700px) {
    .home3-container{
        width: 100%;
        margin-top: 50px;
    }
    .home3-container h3{
    }
    .home3-icons{
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
       
    }

    .home3-icons div{
        min-height: 0;
        width: 50%;
        margin-bottom: 100px;
    }
}
@media (max-width: 400px) {
    .home3-icons div{
        width: 80%;
    }

}