.carbon6-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}
.carbon6-img{
    width: 30%;
}

@media (max-width: 700px) {
    .carbon6-container{
        width: 100%;
    }
    .carbon6-img{
        width: 100%;
        align-self: center;
    }
}