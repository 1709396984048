.home1-container{
    display: flex;
    justify-content: center;
    width: 100%;
}
.home1-bg{
    width: 100%;
    z-index: 2;

}
.home1-texts{
    width: 100%;
    color: var(--color4);
    font-family: var(--font2);
    font-weight: 500;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-top: 100px;
     
}

.home1-texts h1{
    font-size: 44px;
    margin-bottom: 200px;
}
.home1-texts span{
    font-size: 48px;

}

.home1-texts p{
    font-size: 24px;
    color: var(--color4);
    font-family: var(--font2);
    margin: 0;
}

.home1-icons  {
    display: flex;
    margin-top: 100px;
}
.home1-icons div{
    margin: 0 50px;
}
.home1-icons p{
    font-size: 18px;
}



/* Media Query */

@media (max-width: 800px) {
    .home1-texts{
    }
    .home1-texts h1{
        font-size: 25px;
    }
    .home1-texts p{
        font-size: 16px;
    }
    
    .home1-icons div{
        margin: 0 20px ;
     
    }
    .home1-icons p{
        font-size: 12px;
    }  
}

@media (max-width: 700px){
    .home1-texts{
        margin-top: 30px;
    }
    .home1-texts h1{
        font-size: 20px;
        margin-bottom: 90px;
    }
    .home1-icons{
        margin-top: 30px;
        align-items: flex-start;
        justify-content: center;
        margin-top: 15px;
    }
    .home1-icons p{
        width: 100%;
        font-size: 10px;
    }
    .home1-icons img{
        width: 30%;
        max-width: 20px;
    }
   
}

@media (max-width: 400px){
    .home1-texts h1{
        font-size: 20px;
        margin-bottom: 20px;
    }
}




