.tech2-container{
    width: 100%;
    z-index: 1;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow:hidden
}

.tech2-container img{
    position: relative;
    width: 50%;
}

.tech2-scroll{
    display: flex;
    margin: 50px 0 0;
}

.tech2-scroll img{       
    width: 30%;
    margin-right: 50px;
}

.tech2-tree1{
    right: 0;
    height: 60vh;
    width: 30%;
    position: absolute !important;
    top: 0;
    transform: rotate(180deg);
}

.tech2-text{
    width: 50%;
}