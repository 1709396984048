.home2-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color2);
    font-family: var(--font1);
    border-radius: 75px;
    width: 80%;
    padding: 100px 0;
    margin: -350px auto 0;
    text-align: center;
    z-index: 3;
    position: relative;

}
.home2-container div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.home2-container h2{
    color: var(--color4);
    font-size: 48px;
    margin: 0;
}
.home2-logo{
    margin: 0 0  8px 20px;
    z-index: 1;
}

.home2-container p{
    color: var(--color4);
    font-family: var(--font1);
    font-size: 24px;
    width: 50%;
    z-index: 1;
    font-weight: 500;
}
/* .home2-icons{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    width: 60%;
    z-index: 1;

}
.home2-icons img{
    width: 80px;
    z-index: 1;

}
.home2-icons img:last-child{
    width: 10%;
}
.home2-icon p{
    text-align: center;
    width: 90%;
} */



@keyframes scaleUp {
    0%{
        transform: scale(0);
    }70%{
        transform: scale(1.1);
    }100%{
        transform: scale(1);
    }
}

/* Media Query */
@media (min-width: 1500px) {
    .home2-container{

        background-size: cover;
      

    }
}
@media (max-width: 1300px) {
    .home2-container{
        padding: 30px 0;
        margin-top: -150px;
    }
    .home2-container p:first-child{
        width: 71%;
    }
   .home2-icons{
        width: 80%;
   }
  
}

@media (max-width: 900px){
    .home2-container{
        margin-top: -70px;
        width: 90%;
    }
    .home2-container h2{
        font-size: 40px;
    }
    .home2-logo{
        width: 50%;
    }

  
}

@media (max-width: 650px){
    .home2-container{
        margin-top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0;
    }
    .home2-logo{
        margin: 0;
        margin-left: 20px;
    }
    
    .home2-container p{
        width:90%;
        font-size: 20px;
    }
   
}

@media (max-width: 400px){
    .home2-container div {
        flex-direction: column;
    }
}