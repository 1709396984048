
.solutions1-section1{
    width: 100%;
    background-image: url('../source/solutionBg1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 'center';
    padding: 250px 0;
    display: flex;
    align-items: center;
    justify-content: center;

}
.solutions1-section2{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0;
}

.solutions1-section2 h2{
    color: var(--color2);
    text-align: center;
    font-family: var(--font1);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.solutions1-section2 p{
    color: #000;
    font-family: var(--font1);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    width: 70%;
}
.solutions1-section2 img{
    width: 80%;
    border-radius: 59px;
    margin-top: 80px;
}
@media (max-width: 650px) {
    .solutions1-section1 img{
        width: 90%;

    }
    .solutions1-section2 img{
        width: 90%;
        border-radius: 20px;
    }
}