.carbon1-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.carbon1-img{
    width: 100%;
}
.carbon1-contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.carbon1-title{
    margin-top: -70px;
}
.carbon1-contents p{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--color3);
    width: 80%;
    margin: 100px 0;
}
.carbon1-verra{
    width: 25%;
    margin-bottom: 50px;
}


@media (max-width: 550px) {
    .carbon1-title {
        width: 90%;
    }
    .carbon1-contents p{
        margin-top: 50px;
    }
    .carbon1-verra{
        width: 60%;
    }
    
}


