.about6-container{
    width: 90%;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.about6-container img{
    width: 80%;
    max-width: 800px;
   
}

.about6-description{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 100px;
}

.about6-description img{
    width: 50%;
    border-radius: 44px;
    max-width: 600px;

}
.about6-description p{
    width: 40%;
    color: var(--color3);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: var(--font1);
}

@media (max-width: 600px) {
    .about6-description{
        flex-direction: column-reverse;
    }
    .about6-description p{
        width: 100%;
        text-align: center;
    }
    .about6-container img{
        width: 100%;
        border-radius: 10px;
    }
}