.contact-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font2);
}
.contact-container h2{
    color: var(--color1);
    font-size: 36px;
    text-align: start;
    width: 80%;
}
.contact-container form{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 150px 10px; 
    width: 80%;
    z-index: 1;

}
.contact-input {
    display: grid;
    flex-direction: column;
}
.contact-input label {
    color: var(--color2);
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.contact-input img {
    width: 30px;
    margin: 0 5px;
}
.contact-input input,textarea {
    background-color: grey;
    color: var(--color4);
    padding: 20px;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    outline-color: var(--color1);
    outline-width: .5;
    max-height: 20px;
}

.contact-message{
    grid-column: 2;
    grid-row: 1/2;
    place-self: center;
    margin-top: 30px;
}
.contact-message textarea{
    height: 100%;  
    max-height: none;

}
.contact-mail{
    grid-column: 1;
    margin-top: -100px;
    place-self: top;
    place-content: top;
}
.contact-name {
    place-self:top;
    width: 100%;
}
.contact-container button{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color4);
    font-weight: 500;
    font-size: 25px;
    border: none;
    border-radius: 20px;
    background-color: var(--color2);
    padding: 30px;
    height: 50%;
    width: 50%;
    place-self: end center;
    margin-top: -150px;
    cursor: pointer;

}
.contact-container button:hover{
    opacity: 1.1;
    transform: scale(1.1);
}
.contact-container button:active{
    opacity: .9;
    transform: scale(.9);
}

button img{
    margin-left: 20px;
}

@media (max-width: 800px) {
    .contact-container form{
        grid-template-columns: repeat(1,1fr);
    }
    .contact-name{
        margin-bottom: 0;
    }
    .contact-message{
        grid-column: 1;
        grid-row: 3;
        width: 100%;
        margin-top: -100px;
        margin-bottom: 20px;
    }
    .contact-container button{
        margin-bottom: 100px;
        font-size: 16px;
    }
    
}