.about3-container{
    padding: 100px 150px 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -200px;
    position: relative;
    z-index: 3;
}
.about3-texts{
    background-color: var(--color4);
    padding: 50px 100px 120px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 71px;
}
.about3-texts img{
    margin-top: -100px;
    margin-left: -100px;
}
.about3-texts p{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--color3);
    margin: 0;
}
.about3-img2{
    margin-top: -60px;
    width: 70%;
}

@media (max-width: 800px) {
    .about3-container{
        margin: 0px;
        padding: 0 20px;
    }
    .about3-texts{
        width: 100%;
        padding: 0 10px;
        box-shadow: none;
        text-align: center;
    }
    .about3-texts img{
       width: 105%; 
       margin: 0;
    }
    .about3-img2{
        margin-top: 50px;
        width: 90%;
    }
}