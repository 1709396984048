.about5-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    background-color: white;
    padding: 100px 0;
}
.about5-logo{
    width: 30%;
    z-index: 1 !important;
}
.about5-container h2{
    font-family: var(--font2);
    color: var(--color2);
    font-size: 48px;
}
.about5-elements{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 50px;
}

.about5-element{
    background-color: var(--color1);
    min-width: 220px;
    height: 100%;
    width:220px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

}
.about5-element::before{
    margin-left: 40%;
    transform: scale(1.2);
}


.about5-container img{
    right: 50%;
    margin-top: -30px;
    z-index: 2;
}
.about5-container p{
    background-color: var(--color4);
    color: var(--color3);
    font-family: var(--font2);
    padding: 15px;
    border-radius: 10px;
    height: 110%;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-top: 50px;
}


