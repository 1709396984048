.about4-container{
    margin-top: -300px;
    position: relative;
    z-index: 3;
    font-family: var(--font2);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about4-title{
    width: 40%;
    margin-bottom: 50px;
}
.about4-container p{
    font-size: 16px;
    color: var(--color3);
    width: 100%; 
}

.about4-mission{
    width: 100%; 
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 50px 0;
}
.about4-mission img{
    width: 40%;
}

.about4-team{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 50px;
}
.about4-people{
    min-height: 450px;
    position: relative;
    width: 22%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px;
    padding: 25px 0;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);    
    border-radius: 71px;
}
.about4-people img{
    width: 40%;
}
.img-hermna{
    width: 52% !important; 
    margin-top: -20px !important;
}
.about4-people:nth-child(2) h3{
  
}
.about4-people p{
    font-size: 13px;
    width: 75%;
}
.about4-container h2{
    color: var(--color2);
    font-size: 48px;
}
.about4-container h3{
    color: var(--color3);
    font-size: 20px;
    margin-bottom: 0;
}
.about4-container h4{
    color: var(--color3);
    font-size: 18px;
    margin: 0;
}
.about4-container h5{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--color3);
    width: 60%;
}
.about4-separate{
    width: 112px;
    height: 17px;
    background-color: var(--color2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 27px;
}

@media (max-width: 800px){
    .about4-container{
        margin-top: 0;
    }
    .about4-container img{
        width: 100%;
    }
    
}
@media (max-width: 800px){
    .about4-team{
        flex-direction: column;
        align-items: center;
    }
    .about4-team img{
        width: 80%;
    }
    .about4-people{
        width: 60%;
        margin-bottom: 50px;
    }
    .about4-people img{
        width: 50%;
    }

}

@media (max-width: 800px){
    .about4-people{
        width: 90%;
    }
  
}

@media (max-width: 450px){
    .about4-container h5{
        width: 90%;
    }
}

