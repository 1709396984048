.carbon2-container{
    width: 80%;
    margin: 100px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carbon2-container img{
    width: 55%;
    max-height: 451px;
}
.carbon2-texts{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--font2);
    margin-left: 40px;
    padding: 50px 0;
}
.carbon2-texts h3{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--color1);
}
.carbon2-container h2{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--color2);
}
.carbon2-container p{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--color3);
}

@media (max-width: 800px) {
    .carbon2-container{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .carbon2-container img{
        width: 80%;
    }
    .carbon2-texts{
        width: 80%;
    }
    .carbon2-texts h3{
        text-align: center;
    }
}