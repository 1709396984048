.tech1-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 100px 0;
    
}
.tech1-container h2{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 42px;
    font-family: var(--font2);
    color: var(--color1);
}
.tech1-icons{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    z-index: 1;
    place-self: centrer;
    margin: 50px 0;
}
.tech1-icon{
    background-color: #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 50px;
    padding: 15px 5px;
    place-self: center;
    height: 100%;
}
.tech1-icon img{
    width: 80%;
}
.tech1-icon p{
    font-family: var(--font2);
    color: var(--color3);
    width: 80%;
    margin-top: 40px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
}

