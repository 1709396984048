.home5-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font2);
    width: 85%;
    margin: 0 auto;
}


.home5-container img{
    width: 50%;
}

.home5-container h2{
    color: var(--color2);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.home5-container h3{
    width: 70%;
    color: #000;
    font-family: Montserrat Alternates;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 100px;
}
.home5-container p{
    color: #000;
    font-family: Montserrat Alternates;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    text-align: center;
}

.home5-container span{
    font-weight: bold;
}

@media (max-width: 600px){
    .home5-container img{
        width: 80%;
    }
    .home5-container p{
        width: 100%;
    }
}