.carbon3-container{
    width: 80%;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.carbon3-container video{
    border-radius: 15px;
    width: 70%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.carbon3-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.carbon3-icons div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    margin-top: 100px;
}
.carbon3-icons h3{
    font-family: var(--font2);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--color1);
    text-align: center;
}

@media (max-width: 550px) {
    .carbon3-container {
        width: 100% ;
        margin: 0 ;
    }
    .carbon3-container video{
        width: 100%;
    }
    .carbon3-icons{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        place-items: center;
        margin-bottom: 50px;
    }
    .carbon3-icons img{
        width: 150%;
    }
    .carbon3-icons h3{
        font-size: 16px;
    }
}